/* h2 {
	color: #333;
	text-align: center;
	text-transform: uppercase;
    font-family: 'Noto Sans', sans-serif;
	font-weight: bold;
	position: relative;
	margin: 30px 0 60px;
}
h2::after {
	content: "";
	width: 140px;
	position: absolute;
	margin: 0 auto;
	height: 3px;
	background: #db584e;
	left: 0;
	right: 0;
	bottom: -10px;
    opacity: .8;
} */
.col-center {
	margin: 0 auto;
	float: none !important;
}
.carousel {
	margin: 50px auto;
    /* margin-left: 100px !important; */
	padding: 20px 70px;
    border: 1px solid rgb(192, 189, 189);
}
.carousel-item {
	color: #999;
	font-size: 14px;
    text-align: center;
	overflow: hidden;
    min-height: 290px;
}
.carousel .item .img-box {
	width: 100px;
	height: 100px;
	margin: 0 auto;
	padding: 5px;
	border: 1px solid #ddd;
	border-radius: 50%;
}
.carousel .img-box img {
	width: 100%;
	height: 100%;
	display: block;
	border-radius: 50%;
}
.carousel .testimonial {
	padding: 30px 0 10px;
}
.carousel .overview {	
	font-style: italic;
}
.carousel .overview b {
	text-transform: uppercase;
	color: #db584e;
}
.carousel .carousel-control {
	width: 40px;
    height: 40px;
    margin-top: -20px;
    top: 50%;
	background: none;
}
.carousel-control i {
    font-size: 30px;
	line-height: 42px;
    position: absolute;
    display: inline-block;
	color: rgba(0, 0, 0, 0.8);
    text-shadow: 0 3px 3px #e6e6e6, 0 0 0 #000;
}
.carousel .carousel-indicators {
	bottom: -40px;
}
.carousel-indicators li, .carousel-indicators li.active {
	width: 10px;
	height: 10px;
	margin: 1px 3px;
	border-radius: 50%;
}
.carousel-indicators li {	
	background: #999;
	border-color: transparent;
	box-shadow: inset 0 2px 1px rgba(0,0,0,0.2);
}
.carousel-indicators li.active {	
	background: #555;		
	box-shadow: inset 0 2px 1px rgba(0,0,0,0.2);
}
.container12{
    max-width: 1200px;
}
.timmer-icon-font{
    font-size: 30px;
}
.timmer-conter{
    font-size: 40px;
    text-align: center;
    font-weight: 700;
}
.testimonial-intro h2{
    text-transform:uppercase;
    font-weight: 700;
    font-size: 28px;
}
.testimonial-intro p{
    font-family: 'Volkhov',serif;
    font-style: italic;
    color: #666;
    font-size: 18px;
}