.project-hero{
    height: 60vh;
    background: linear-gradient(to left,#585565bb,#322e40ba);
    color: #6fb7eb;
    /* background-size: cover; */
}
.project-hero h1{
    font-weight: 100;
    font-size: 45px;
    letter-spacing: 6px;
    padding-bottom: 15px;
    margin-top: 0;
    text-transform: capitalize;

}
.project-hero p{
    font-size: 20px;
    font-weight: 300;
    letter-spacing: 1px;
    word-spacing: 3px;
}
.category-button{
    
}
@media (max-width:600px) {
    .catag{
        margin: 0;
        padding: 0;
        display: block !important;
    }
    .category-button{
        margin: 10px !important;
        /* padding: 0 !important; */
    }
    .project-sec img{
        height: 150px;
        width: 150px !important;
    }
    .project-hero h1 {
        font-weight: 100;
        font-size: 16px;
        letter-spacing: 2px;
        padding-bottom: 15px;
        margin-top: 0;
        text-transform: capitalize;
    }
    .project-hero p {
        font: 14px;
    }
}