
.card-degign{
   /* height: 350px; */
border: none;
transition: all 0.8s ease;
}
.card-icon {
   font-size: 60px;
   color: #4A4656;
}
.card-degign h4{
   color: #666;
}
.card-degign p{
   color: #888;
   line-height: 1.8;
}
.card-heading{
   margin-top: 80px;
}
.card-heading h2{
   text-transform: uppercase;
   font-size: 28px;
   font-weight: 700;
}
.card-heading p{
   font-family: 'Volkhov',serif;
   font-style: italic;
   color: #666;
   font-size: 20px;
}

.card-degign:hover  {
   /* color: white !important; */
   /* background-color: linear-gradient(to left bottom,  #666,#4A4656 ); */
   /* background: linear-gradient(to right bottom ,white, #4a465693,white); */
   transform: translateY(-1rem);
   /* border: 1px solid gray; */
   
}
/* .card-design:hover .card-desciption,
.card-design:hover .card-desciption {
  color: #ff0000 !important;
} */